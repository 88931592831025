@import "../../../Styles/Utilities.scss";
#feature-info {
  .feature-item {
    gap: 50px;
    margin: auto;
    display: grid;
    grid-template-columns: 35% auto;
    grid-template-areas: "pickupImageArea pickupContentArea";
    .pickup-image {
      width: 100%;
      height: 100%;
      grid-area: pickupImageArea;
      flex-shrink: 0;
      border-radius: 16px;
      object-fit: cover;
      // box-shadow: 0px 2px 8px rgba(36, 39, 44, 0.1);
    }
    .pickup-content {
      grid-area: pickupContentArea;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      overflow: hidden;
      .title {
        font-weight: bold;
        font-size: clamp(24px, 4vw, 32px);
        // line-height: var(--section-header-line-height);
        text-transform: capitalize;
        margin-bottom: 0px;
      }
      .description {
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.006em;
        line-height: 25px;
        opacity: 0.8;
        margin: 10px 0px;
      }
      .option {
        font-weight: 500;
        font-size: 17px;
        letter-spacing: 0.006em;
        display: grid;
        grid-template-columns: 20px auto;
        align-items: top;
        gap: 20px;
        margin-top: 15px;
        img {
          height: 20px;
          width: 20px;
          margin-top: 4px;
        }
      }
      .feature-item-link {
        margin-top: 25px;
        font-size: 18px;
        text-decoration: none;
        width: fit-content;
        border-bottom: 2px solid $app-primary-color;
        color: $default-font-color;
        // text-decoration-color: $app-primary-color;
      }
    }
  }
  .side-by-side {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 50px;
    .feature-item {
      display: block !important;
      margin: unset !important;
      .lazy-load-image-background {
        display: none !important;
      }
    }
  }
  //blue background
  // .feature-item .pickup-content:before {
  //   content: "";
  //   height: 600px;
  //   width: 600px;
  //   background-color: #f7fdff;
  //   position: absolute;
  //   border-radius: 56%;
  //   z-index: -1;
  //   right: -65px;
  // }
  .feature-item:nth-child(even) {
    grid-template-areas: "pickupContentArea pickupImageArea";
    grid-template-columns: auto 35%;
  }
  @media only screen and (max-width: 992px) {
    .feature-item {
      grid-template-columns: 40% auto;
    }
    .feature-item:nth-child(even) {
      grid-template-columns: auto 40%;
    }
  }
  @media only screen and (max-width: 767px) {
    .feature-item {
      grid-template-columns: auto !important;
      grid-auto-flow: row;
      grid-template-areas: "pickupImageArea" "pickupContentArea" !important;
      gap: 0px;
    }
    .feature-item .pickup-image {
      height: fit-content;
      // display: none;
    }
    .side-by-side {
      grid-template-columns: 100%;
      .feature-item .pickup-image {
        display: none;
      }
    }
  }
}
