.contact-us-supports{
    display:flex,
    
}
@media only screen and (max-width: 767px) {
.contact-us-supports>div{
    width: 100% !important
}
.contact-us-supports{
    flex-direction: column;
    gap: 20px
}
}