.popup-component {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  .popup-content-wrapper.refer-business,
  .popup-content-wrapper.industry-info,
  .popup-content-wrapper.register-interest,
  .popup-content-wrapper.call-support {
    max-width: 400px;
    height: fit-content;
  }
  .popup-content-wrapper {
    max-height: 95%;
    overflow-y: scroll;
    border-radius: 12px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .popup-content-wrapper {
    position: relative;
    width: 95%;
    max-width: 500px;
  }
  .popup-content {
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
  }
  .popup-content::-webkit-scrollbar {
    display: none;
  }

  .cancel-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    text-align: center;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: initial;
  }
  .popup-content {
    padding: 20px;
    padding-top: 40px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(36, 39, 44, 0.1);
    border-radius: 12px;
    .form-title {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
    .form-subtitle {
      color: #979797;
      font-size: 11px;
      text-align: center;
    }
    .form-footer {
      font-size: 11px;
    }
    .popup-input {
      label {
        font-size: 14px;
      }
      input {
        font-size: 14px;
        padding: 24px 12px 7px;
        &::after {
          border-bottom: none;
        }
      }
    }
    .popup-input > div {
      background-color: white;
      border: 1px solid rgba(36, 39, 44, 0.15);
      border-radius: 5px;
      &::before,
      &::after {
        border-bottom: none;
      }
    }
    .popup-input.error > div {
      border: 1px solid red;
    }
    .submit-btn {
      background: linear-gradient(180deg, #1995fc 0%, #007fff 100%);
      border-radius: 4px;
      height: 48px;
      border: none;
      color: white;
      font-weight: 500;
    }
    .submit-btn.disabled {
      opacity: 0.4;
    }

    .popup-radio {
      legend {
        font-size: 12px;
      }
    }
    .popup-tnc {
      font-size: 11px;
      text-align: center;
      a {
        color: #007fff;
      }
    }
  }
  .popup-input.phone {
    input {
      padding: 15px 0px;
    }
  }
  .thankyou{
    svg {
      path{
        fill: #007fff;
      }
    }
  }
  .thankyou.client{
    svg {
      path{
        fill: #0D771E;
      }
    }
  }
  .thankyou {
    text-align: center;
    display: grid;
    gap: 15px;
    // width: 600px;
    svg {
      width: 80px;
      height: 80px;
      margin: auto;
      path {
        
      }
    }
    .title {
      font-size: 32px;
    }
    .subtitle {
      font-weight: bold;
      color: #6d6b6b;
    }
    button {
      width: min-content;
      margin: auto;
      padding: 8px 50px;
      border: none;
      color: white;
    }
  }
  //placeholder
  input::placeholder {
    font-size: 13px;
  }
  .industry-info {
    .form-title,
    .form-subtitle {
      text-align: left;
    }
    .img {
      height: 280px;
      width: 70%;
      border: none;
      background-color: #f7fdff;
      margin: auto;
      margin-bottom: 10px;
      // margin-top: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
    .title {
      font-size: 22px;
      font-weight: 500;
    }
    ul {
      list-style: unset;
      font-size: 14px;
      padding-left: 25px;
      li:nth-child(n + 5) {
      }
    }
  }
  .f-label {
    font-size: 14px;
    // font-weight: 500;
  }
  textarea:focus {
    outline: 0;
  }
}
@media only screen and (max-width: 480px) {
  .popup-component {
    align-items: center;
    .popup-content-wrapper {
      max-height: 96% !important;
      height: min-content !important;
    }
  }
}
