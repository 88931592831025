.pricing-main {
  .title {
    font-size: clamp(20px, 3.5vw, 48px);
    font-weight: 700;
  }
  .sub-title {
    font-size: clamp(14px, 1.5vw, 18px);
    font-weight: 500;
    opacity: 0.7;
    margin-bottom: 30px;
  }
}
