.faq-component {
  .faq-title,
  .faq-subtitle {
    text-align: center;
  }
  .faq-title {
    font-size: clamp(18px, 3vw, 42px);
    font-weight: 700;
  }
  .faq-subtitle {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 30px;
    letter-spacing: 0.006em;
  }

  .faq-question {
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .faq-answer {
    display: none;
    font-size: 18px;
    padding-bottom: 15px;
  }
}
//faq set
.faq-question-set {
  .faq-question {
    padding-right: 30px;
    position: relative;
    &:after {
      $size: 15px;
      content: "";
      border: solid rgba(36, 39, 44, 0.7);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      position: absolute;
      right: 10px;
      top: calc(50% - 11px);
      height: clamp(1px, 1vw, 7px);
      width: clamp(1px, 1vw, 7px);
      transform: rotate(45deg);
      transition-duration: 0.8s;
      transition-property: transform;
    }
  }
  .faq-answer {
    height: 0px;
    overflow: hidden;
    transition: height 1s ease-in;
    // padding-bottom: 10px;
  }
  border-bottom: 1px solid rgba(36, 39, 44, 0.15);
  &:last-child {
    border: none;
  }
}
.faq-question-set:first-of-type {
  margin-top: 20px;
}
.faq-question-set.active {
  .faq-question {
    &:after {
      transform: rotate(-135deg);
    }
  }
  .faq-answer {
    height: auto !important;
  }
}

.faq-question-set.active {
  .faq-answer {
    display: block !important;
  }
}
//end
@media (min-width: 480px) and (max-width: 1024px) {
  .faq-component {
    .faq-subtitle {
      font-size: 20px !important;
    }
    .faq-question {
      font-size: 24px !important;
      // line-height: 60px !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  .faq-component {
    .faq-subtitle {
      font-size: 16px !important;
    }
    .faq-question {
      font-size: 18px !important;
      // line-height: 40px !important;
    }
    .faq-answer {
      font-size: 16px !important;
    }
  }
}
