.banner-main-wrapper {
  position: relative;
  .banner-main {
    height: 500px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 15px;
    .banner-main-hero-image {
      // position: absolute;
      width: 40%;
      flex-shrink: 0;
      height: 100%;
      object-fit: contain;
      object-position: center;
      z-index: -1;
      opacity: 0.8;
    }
    .banner-main-content {
      color: #242f2c;
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      text-transform: uppercase;
      .banner-main-content-title {
        font-size: clamp(14px, 1.5vw, 24px);
        font-weight: 500;
        // background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        width: fit-content;
        // padding-left: 11px;
        // padding-right: 11px;
      }
      .banner-main-content-brief {
        font-size: clamp(21px, 3vw, 48px);
        font-weight: 700;
        // background-color: rgba(0, 0, 0, 0.6);
        margin-top: 10px;
        // padding-left: 11px;
        // padding-right: 11px;

        width: fit-content;
      }
      .banner-main-content-button {
        text-transform: none;
        text-decoration: none;
        margin-top: 10px;
        background: #007fff;
        color: white;
      }
    }
  }
  .banner-main-hero-image-bg {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    object-fit: cover;
    object-position: top;
    opacity: 0.8;
  }
}
@media only screen and (max-width: 479px) {
  .banner-main-wrapper .banner-main .banner-main-content {
    // justify-content: end;
    // padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-main-wrapper .banner-main {
    flex-direction: column-reverse;
    height: fit-content;
    .banner-main-content {
      align-items: center;
      text-align: center;
      button {
        width: 100%;
      }
    }
    .banner-main-hero-image {
      width: 100%;
      min-height: 330px;
    }
  }
}
