@import url("https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap");
@import "./Styles/style.scss";
* {
  font-family: "Poppins", sans-serif;
  // font-family: 'Open Sans', sans-serif;
}

body {
  color: #24272c;
  overflow-x: hidden;
  .MuiPopover-root {
    overflow: -moz-scrollbars-vertical !important;
    overflow-y: scroll;
  }
}
div {
  box-sizing: border-box;
}
.App {
  width: 100vw;
  padding: 0px 0px;
}

ul {
  list-style: none;
}
.cont-mb {
  margin-bottom: 100px !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input {
  outline: none;
}
.fa {
  // padding: 20px;
  // font-size: 30px;
  // width: 50px;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-header {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: var(--section-header-font-size);
  line-height: var(--section-header-line-height);
  text-align: center;
  color: var(--primary-text-color);
  /* margin-top: 5vh; */
}
.row {
  display: grid;
  grid-row: 2 / span 2;
}
.pointer {
  cursor: pointer;
}
button:focus {
  outline: none !important;
}
.cl-section {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}
.font-rubik {
  font-family: "Rubik", sans-serif;
}
.font-500 {
  font-weight: 500;
}
.font-700 {
  font-weight: 700;
}
.card {
  border: 1px solid rgba(36, 39, 44, 0.1);
}
.text-center {
  text-align: center;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.justify-center {
  justify-content: center;
}
.cta-btn-type1 {
  border: none;
  border-radius: 4px;
  font-size: clamp(14px, 1.8vw, 16px);
  font-weight: 500;
  width: max-content;
  padding: 10px 25px;
  cursor: pointer;
}
button {
  cursor: pointer;
}
/* responsive css */
@media only screen and (max-width: 1200px) {
  #pricing-component .p-row .p-col:first-child {
    // width: 270px !important;
  }
}

@media only screen and (min-width: 768px) {
  #floater.white-floater {
    .top-menu-bar {
      // background-color: white;
      box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.25);
      svg,
      path {
        stroke: #007fff;
      }
      .reach-logo {
        .reach-title {
          color: #007fff;
          font-weight: 500;
        }
      }
    }
    li {
      color: #007fff;
    }
    li.sign-up {
      color: white;
      background-color: #007fff;
    }
  }
}
.reach-text-input {
  width: 100%;
  background-color: white;

  label {
    font-size: 14px;
  }
  div {
    &:hover {
      background-color: white;
    }
    outline: none;
    background-color: white;
    border: 1px solid rgba(36, 39, 44, 0.15);
    border-radius: 5px;
    &::before,
    &::after {
      border-bottom: none;
    }
  }
  input {
    font-size: 14px;
    padding: 24px 12px 7px;
    &::after {
      border-bottom: none;
    }
  }
}
.reach-text-input.error > div {
  border: 1px solid red;
}
.reach-text-area {
  outline: none;
}
@media only screen and (max-width: 768px) {
  /*768 and more*/
  #floater {
    position: relative !important;
  }

  .top-menu-bar {
    padding-left: 40px;
  }

  .cont-mb {
    margin-bottom: 50px !important;
  }
}
.phone-number-text-input {
}
