@import "./Utilities.scss";
.header-wrapper {
  height: 74px;
  // height: fit-content;
  .header {
    position: fixed;
    background-color: $app-primary-color;
    left: 0;
    right: 0;
    transition: background 0.2s ease-in-out;
    // height: "73px";
    z-index: 5;
    .header-content {
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      transition: background 0.2s ease-in-out;

      .h-left {
        display: flex;
        align-items: center;
        svg {
          height: 55px;
          width: 45px;
        }
        .reach-title {
          font-size: 30px;
          margin-left: 16px;
          font-weight: 500;
          color: white;
        }
      }
      .h-main-elements {
        display: flex;
        align-items: center;
        .fa-bars {
          display: none;
          text-align: right;
          font-family: "FontAwesome" !important;
          font-size: 30px;
          color: white;
          padding-right: 5px;
        }
        li {
          display: block;
          // border-radius: 5px;
          padding-bottom: 5px;
          font-size: clamp(10px, 1.8vw, 16px);
          margin-right: clamp(10px, 2vw, 25px);
          margin-left: clamp(10px, 2vw, 25px);
          font-weight: 500;
          align-items: center;
          color: white;
          &:hover {
            cursor: pointer;
          }
        }
        li:last-child {
          text-align: center;
          background: #fff;
          color: #007fff;
          justify-content: center;
          border-radius: 4px;
          align-items: center;
          margin-right: 0px;
          margin-left: clamp(10px, 2vw, 25px);
          padding: 10px clamp(10px, 2vw, 25px);
        }
        li.active {
          border-bottom: 3px solid white;
        }
      }
      .header-sidebar {
        // display: none;
        background-color: transparent;
        height: 100vh;
        width: 0px;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        transition: 0.3s;
        .header-sidebar-content {
          background-color: #222;
          width: 80%;
          float: right;
          padding-bottom: 40px;
          color: #fff;
          .h-side-elements {
            flex-direction: column;
            align-items: flex-start;
            padding: 2px 32px 0 36px;
            .fa-bars {
              font-family: "FontAwesome" !important;
              text-align: right;
              width: 100%;
              font-size: 30px;
              padding-top: 7px;
              transform: translateX(10px);
            }
            li {
              height: 55px;
              font-size: 18px;
              display: flex !important;
              padding-left: 0;
            }
            li:last-child {
              text-align: center;
              background: #fff;
              color: #007fff;
              justify-content: center;
              margin-top: 20px;
              border-radius: 4px;
              align-items: center;
            }
          }
        }
      }
      .header-sidebar.active {
        width: 100vw;
      }
    }
  }
  .header[data-type="type2"] {
    $color1: white;
    $color2: $client-primary-color;
    background-color: $color2;
    box-shadow: 0px 4px 34px rgba(36, 39, 44, 0.05);

    .header-content {
      svg {
        path {
          stroke: $color1;
        }
      }
      .reach-title {
        color: $color1;
      }
      .h-main-elements {
        li,
        .fa-bars {
          color: $color1;
        }
        li:last-child {
          color: $color2 !important;
          background-color: $color1;
        }
      }
      .h-side-elements {
        li:last-child {
          color: $client-primary-color !important;
        }
      }
    }
  }
  .header-inverse[data-type="type1"] {
    background-color: white;
    box-shadow: 0px 4px 34px rgba(36, 39, 44, 0.05);
    .reach-title {
      color: $app-primary-color !important;
    }
    .h-left {
      color: $app-primary-color !important;
      svg {
        path {
          stroke: $app-primary-color !important;
        }
      }
    }
    .h-main-elements {
      li {
        color: $app-primary-color !important;
      }
      li.active {
        border-color: #007fff;
      }
      li:last-child {
        color: white !important;
        background-color: $app-primary-color !important;
      }
      .fa-bars {
        color: $app-primary-color;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .header-wrapper {
    // height: fit-content !important;
    height: 55px;
  }
  .header {
    // position: relative !important;
  }
  .header {
    .header-content {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .h-main-elements i {
      display: inline-block !important;
    }
    .h-main-elements li {
      display: none !important;
    }
    .reach-title {
      margin-left: 7px !important;
      font-size: 20px !important;
    }
    .h-left {
      svg {
        height: 35px !important;
        width: 35px !important;
      }
    }
  }
  .header-sidebar {
  }
}
