html {
  --primary-text-color: #24272c;
  --primary-font: Poppins;
  --secondary-font: Roboto;
  --primary-background: #ffffff;
  --max-width: 1440px;
  --min-width: 360px;
}
$app-max-width: 1200px;

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  html {
    --section-header-font-size: 54px;
    --section-header-line-height: 75px;
    --feature-card-width: 350px;
    --feature-card-height: 320px;
    --primary-text-font-size: 16px;
    --primary-feature-header-font-size: 24px;
    --feature-image-height: 42px;
    --feature-header-margin-top: 40px;
    --feature-header-margin-bot: 16px;
    --feature-margin: 15px 20px;
    --holder-width: 1280px;
    --feature-header-bottom: 60px;
    --feature-show-view-all: none;
    --show-button-bar: none;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  html {
    --section-header-font-size: 48px;
    --section-header-line-height: 68px;
    --feature-card-width: 399px;
    --feature-card-height: 309px;
    --primary-text-font-size: 16px;
    --primary-feature-header-font-size: 24px;
    --feature-image-height: 22px;
    --feature-header-margin-top: 40px;
    --feature-header-margin-bot: 16px;
    --feature-margin: 15px 20px;
    --holder-width: 1000px;
    --feature-show-view-all: none;
    --feature-header-bottom: 60px;
    --show-button-bar: inline;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  html {
    --section-header-font-size: 42px;
    --section-header-line-height: 62px;
    --feature-card-width: 380px;
    --feature-card-height: 300px;
    --primary-text-font-size: 16px;
    --primary-feature-header-font-size: 24px;
    --feature-image-height: 42px;
    --feature-header-margin-top: 30px;
    --feature-header-margin-bot: 16px;
    --feature-margin: 14px 18px;
    --holder-width: 740px;
    --feature-header-bottom: 60px;
    --feature-show-view-all: none;
    --show-button-bar: inline;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  html {
    --section-header-font-size: 36px;
    --section-header-line-height: 54px;
    --feature-card-width: 370px;
    --feature-card-height: 280px;
    --primary-text-font-size: 16px;
    --primary-feature-header-font-size: 24px;
    --feature-image-height: 42px;
    --feature-header-margin-top: 30px;
    --feature-header-margin-bot: 14px;
    --feature-margin: 10px 14px;
    --holder-width: 740px;
    --show-button-bar: inline;
    --feature-show-view-all: none;
    --feature-header-bottom: 32px;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  html {
    --section-header-font-size: 30px;
    --section-header-line-height: 48px;
    --feature-card-width: 350px;
    --feature-card-height: 240px;
    --primary-text-font-size: 15px;
    --primary-feature-header-font-size: 20px;
    --feature-image-height: 36px;
    --feature-header-margin-top: 26px;
    --feature-header-margin-bot: 12px;
    --feature-margin: 15px 20px;
    --feature-margin: 8px 10px;
    --holder-width: 440px;
    --show-button-bar: inline;
    --feature-show-view-all: none;
    --feature-header-bottom: 28px;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  html {
    --section-header-font-size: 24px;
    --section-header-line-height: 32px;
    --feature-card-width: 320px;
    --feature-card-height: 220px;
    --primary-text-font-size: 14px;
    --primary-feature-header-font-size: 16px;
    --feature-image-height: 32px;
    --feature-header-margin-top: 20px;
    --feature-header-margin-bot: 12px;
    --feature-margin: 6px 8px;
    --holder-width: 360px;
    --feature-show-view-all: inline;
    --feature-header-bottom: 24px;
  }
}
