@import "../../../Styles//Utilities.scss";
.pickup-component {
  gap: 50px;
  margin: auto;
  display: grid;
  grid-template-columns: 35% auto;
  grid-template-areas: "pickupImageArea pickupContentArea";
  .pickup-image {
    width: 100%;
    height: 520px;
    grid-area: pickupImageArea;
    flex-shrink: 0;
    border-radius: 16px;
    object-fit: contain;
    // box-shadow: 0px 2px 8px rgba(36, 39, 44, 0.1);
  }
  .pickup-content {
    grid-area: pickupContentArea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    .title {
      font-weight: bold;
      font-size: clamp(24px, 4vw, 48px);
      line-height: var(--section-header-line-height);
      text-transform: capitalize;
    }
    .description {
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.006em;
      line-height: 25px;
      opacity: 0.8;
      margin: 10px 0px;
    }
    .option {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.006em;
      display: grid;
      grid-template-columns: 20px auto;
      align-items: top;
      gap: 20px;
      margin-top: 15px;
      img {
        height: 20px;
        width: 20px;
        margin-top: 4px;
      }
    }
    .feature-item-link {
      margin-top: 25px;
      font-size: 18px;
      text-decoration: none;
      width: fit-content;
      border-bottom: 2px solid $app-primary-color;
      color: $default-font-color;
      font-weight: bold;
      // text-decoration-color: $app-primary-color;
    }
  }
}
//blue background
// .pickup-component .pickup-content:before {
//   content: "";
//   height: 600px;
//   width: 600px;
//   background-color: #f7fdff;
//   position: absolute;
//   border-radius: 56%;
//   z-index: -1;
//   right: -65px;
// }
.pickup-component:nth-child(even) {
  grid-template-areas: "pickupContentArea pickupImageArea";
  grid-template-columns: auto 35%;
}
@media only screen and (max-width: 992px) {
  .pickup-component {
    grid-template-columns: 40% auto;
  }
  .pickup-component:nth-child(even) {
    grid-template-columns: auto 40%;
  }
}
@media only screen and (max-width: 767px) {
  .pickup-component {
    grid-template-columns: auto !important;
    grid-auto-flow: row;
    gap: 0px;
    grid-template-areas: "pickupImageArea" "pickupContentArea" !important;
  }
  .pickup-component .pickup-image {
    height: fit-content;
    // margin: auto;
  }
}
