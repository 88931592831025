.app-connection {
  //   padding-top: clamp(25px, 5%, 60px);
  //   padding-bottom: clamp(25px, 5%, 60px);
  .title {
    font-weight: 700;
    font-size: clamp(22px, 2.4vw, 32px);
    margin-bottom: 20px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 30px;
    opacity: 0.8;
    margin-bottom: 15px;
  }
  .sms-link-country-flag {
    width: 85px;
    background: white;
    padding: 7px 5px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #e0e0e0;
    border-right: none;
    .MuiInput-underline::before {
      border: none;
    }
  }
  .sms-link-input {
    border: 1px solid #e0e0e0;
    border-left: none;
    border-radius: 0px 0px 0px 0px;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;
    outline: none;
    min-width: 0px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      font-size: 16px;
    }
  }
  .right-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-left: 5%;
  }
  .app-connection-content {
    display: flex;
    flex-direction: row;
    // background-color: rgba(13, 119, 30, 0.1);
    // padding: 75px 60px;
    border-radius: 12px;
    padding: clamp(30px, 4.5vw, 70px) clamp(20px, 3vw, 70px);
    gap: 5%;
    // padding-left: 13vw;
  }
  button {
    border-radius: 0px !important;
    box-shadow: none;
  }
  .right-cont {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .partner-img {
    border-radius: 25px;
    // border: 10px solid white;
    height: 450px;
    width: 33%;
    object-fit: contain !important;
    object-position: top;
  }
  .apps-store {
    width: 100%;
    max-width: 320px;
    img {
      // width: 50%;
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 767px) {
  .app-connection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .app-connection-content {
      flex-direction: column !important;
      // padding-left: clamp(20px, 3vw, 70px) !important;
      .partner-img {
        width: 70% !important;
        margin: auto;
        margin-bottom: 10px;
      }
    }
    .sms-link-country-flag {
      width: 80px;
      padding: 7px 1px;
    }
    .sms-link-input {
      flex: 1;
      width: auto;
      padding-left: 0px;
      // &::-moz-placeholder,
      &::-webkit-input-placeholder {
        font-size: 14px;
      }
      &::-moz-placeholder {
        font-size: 14px;
      }
    }
    button {
      // flex-basis: 100%;
      // width: 100% !important;
      height: 45px;
    }
  }
  .arrow-right {
    top: calc(50% - 25px) !important;
  }
}
