.bottomBar {
  position: fixed;
  width: 100%;
  height: 60px;
  background: white;
  box-shadow: 0px -8px 24px rgba(36, 39, 44, 0.1);
  bottom: -1px;
  left: 0;
  z-index: 10;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  display: none;
  button {
    background: #007fff;
    color: var(--primary-background);
    border: none;
    border-radius: 4px;
    font-size: clamp(14px, 1.8vw, 16px);
    font-weight: 500;
    width: max-content;
    padding: 10px 15px;
  }
  .note {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 133.2%;
    font-feature-settings: "case" on;
    color: var(--primary-text-color);
  }
}

@media only screen and (max-width: 767px) {
  .bottomBar {
    display: flex;
  }
  #footer {
    margin-bottom: 60px !important;
  }
}
