@import "../../../Styles/Utilities.scss";
// .feature-catalog {
//   .feature {
//     margin: var(--feature-margin);
//     display: block;
//   }
//   .grid-container {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     grid-gap: 25px;
//     margin-bottom: 25px;
//   }
//   .content {
//     max-width: var(--holder-width);
//     min-width: var(--min-width);
//     height: auto;
//     box-shadow: 0px 10px 24px rgba(36, 39, 44, 0.1);
//     padding: 3vw;
//     background-color: #f7fdff;
//   }
//   .sub-header {
//     margin-top: 16px;
//     margin-bottom: var(--feature-header-bottom);
//     text-align: center;
//     font-size: clamp(14px, 1.5vw, 22px);
//   }
//   @media only screen and (max-width: 992px) {
//     .grid-container {
//       grid-template-columns: 1fr 1fr;
//     }
//   }
//   @media only screen and (max-width: 480px) {
//     .grid-container {
//       grid-template-columns: 1fr;
//     }
//   }
//   .signup-banner {
//     text-align: center;
//     background-color: #f4f4f4;
//     width: 100%;
//     padding-block: 40px;
//     p {
//       font-size: clamp(13px, 2vw, 30px);
//       margin-bottom: 1%;
//       font-weight: 500;
//     }
//     button {
//       width: fit-content;
//       margin-top: clamp(10px, 3%, 15px);
//       background-color: $app-primary-color;
//       // border: none;
//       // border-radius: 4px;
//       // font-size: clamp(15px, 1.8vw, 16px);
//       // font-weight: 600;
//       margin-top: 25px;
//       color: white;
//       width: max-content;
//       // padding: 6px 25px;
//     }
//   }
// }
// .feature-catalog {
//   .feature-item {
//     background: var(--primary-background);
//     box-shadow: 0px 10px 24px rgba(36, 39, 44, 0.1);
//     border-radius: 12px;
//     padding: 25px;
//     .feature-item-img {
//       display: inline-block;
//       img {
//         height: 100%;
//         width: 100%;
//       }
//       // padding: 10px;
//       // background: green;
//       border-radius: 50%;
//       width: 50px;
//       height: 50px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   }

//   .feature-header {
//     font-family: var(--primary-font);
//     font-style: normal;
//     font-weight: bold;
//     font-size: var(--primary-feature-header-font-size);
//     line-height: 136.2%;
//     text-transform: capitalize;
//     color: var(--primary-text-color);
//     margin-top: var(--feature-header-margin-top);
//     margin-bottom: var(--feature-header-margin-bot);
//   }
//   .feature-content {
//     font-family: var(--secondary-font);
//     font-style: normal;
//     font-weight: normal;
//     font-size: var(--primary-text-font-size);
//     line-height: 136.2%;
//     letter-spacing: 0.006em;
//     color: var(--primary-text-color);
//     opacity: 0.8;
//   }

//   @media (min-width: 768px) {
//     html {
//       --section-header-font-size: 36px;
//       --section-header-line-height: 54px;
//       --feature-card-width: 370px;
//       --feature-card-height: 280px;
//     }
//   }

//   @media only screen and (max-width: 992px) {
//     .section-header br {
//       display: none;
//     }
//   }
//   @media (min-width: 481px) and (max-width: 992px) {
//     .section-header br {
//       display: none;
//     }
//   }
//   @media (min-width: 481px) and (max-width: 767px) {
//     html {
//       --section-header-font-size: 30px;
//       --section-header-line-height: 48px;
//       --feature-card-width: 350px;
//       --feature-card-height: 240px;
//     }
//   }

//   @media (min-width: 320px) and (max-width: 480px) {
//     html {
//       --section-header-font-size: 24px;
//       --section-header-line-height: 32px;
//       --feature-card-width: 320px;
//       --feature-card-height: 220px;
//     }
//   }
// }
// .cont {
//   display: grid;
//   grid-template-columns: repeat(1, 1fr);
//   grid-gap: 15px;
//   .cont-feature-item {
//     background: var(--primary-background);
//     box-shadow: 0px 10px 24px rgba(36, 39, 44, 0.1);
//     border-radius: 12px;
//     padding: 35px;
//     .feature-item-img {
//       display: inline-block;
//       img {
//         height: var(--feature-image-height);
//         width: var(--feature-image-height);
//       }
//       padding: 15px;
//       background: green;
//       border-radius: 50%;
//     }
//   }
// }

.home-features {
  .learn-more {
    margin-top: 0px;
    margin-bottom: 25px;
    font-size: 18px;
    text-decoration: none;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #007fff;
    color: #24272c;
    cursor: pointer;
    font-weight: bold;
  }
  .heading {
    margin-bottom: 40px;
    .main {
      font-size: clamp(18px, 3vw, 42px);
    }
    .subheader {
      font-size: clamp(16px, 1.5vw, 22px);
    }
  }
  .home-features-type2 {
    display: none;
  }
  .home-features-type1 {
    display: flex;
    grid-template-columns: auto 40%;
    flex-direction: row;
    gap: 5vw;
    .feature-right-elem {
      width: auto;
      .description {
        font-size: 18px;
        // font-weight: 500;
        margin-bottom: 10px;
      }
      .feature-image {
        // width: 62%;
        height: 530px;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 700px;
        // max-height: 615px;
        .lazy-load-image-background {
          height: 100% !important;
        }
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .home-features-type1 {
    .features-list {
      flex: 1;
      .feature-item {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 25px;
        img {
          width: 40px;
          height: 40px;
        }
        .label {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
      .feature-item.active {
        .label {
          color: $app-primary-color;
          text-decoration: underline;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .home-features-type1 {
      gap: 20px;
      .feature-right-elem .description {
        font-size: 18px;
      }
      .feature-image {
        // width: 58%;
        height: 480px !important;
      }
      .features-list {
        .feature-item {
          margin-bottom: 15px;
          .label {
            font-size: 18px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .home-features-type1 {
      display: none;
    }
    .home-features-type2 {
      .learn-more {
        font-size: 18px;
      }
      display: block;
      .description {
        font-size: 16px;
        // font-weight: 500;
        margin-bottom: 15px;
      }
      .features-list {
        .feature-item {
          border-bottom: 1px solid rgba(36, 39, 44, 0.15);
          .feature-item-accordian {
            &:after {
              content: "";
              border: solid rgba(36, 39, 44, 0.7);
              border-width: 0 3px 3px 0;
              display: inline-block;
              padding: 4px;
              position: absolute;
              right: 10px;
              top: calc(50% - 7px);
              height: clamp(1px, 1vw, 7px);
              width: clamp(1px, 1vw, 7px);
              transform: rotate(45deg);
              transition-duration: 0.4s;
              transition-property: transform;
            }

            display: flex;
            position: relative;
            align-items: center;
            height: 60px;
            margin-bottom: 5px;
            gap: 10px;
            .label {
              font-size: 18px;
            }
            img {
              width: 30px;
              height: 30px;
            }
          }
          .feature-image {
            // display: none;
            width: 100%;
            overflow: hidden;
            max-height: 0px;
            // -webkit-transition: all 3s ease-in-out;
            // -moz-transition: all 3s ease-in-out;
            // transition: all 3s ease-in-out;
            .lazy-load-image-background {
              width: 100% !important;
              img {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                box-sizing: border-box;
              }
            }
          }
        }
        .feature-item.active {
          .feature-item-accordian {
            &::after {
              transform: rotate(-135deg);
            }
          }
          .feature-image {
            max-height: 1000px;
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
  }
}
