@import "./Utilities.scss";
.footer {
  margin-bottom: 10px !important;
  .footer-content {
    display: grid;
    grid-template-areas: "logoArea listArea contactArea";
    align-items: center;
  }
  .footer-logo {
    grid-area: logoArea;
    height: 150px;
    // width: 12vw;
    svg {
      path {
        stroke: $app-primary-color;
      }
    }
  }
  .footer-list {
    grid-area: listArea;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      height: 35px;
      text-align: center;
    }
    font-weight: 500;
  }
  .footer-contact {
    grid-area: contactArea;
    text-align: right;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px !important;
    .fa {
      font-size: 20px;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      li {
        margin-left: 20px;
        border-radius: 100px;
        height: 35px;
        width: 36px;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #007bff;
        a {
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
        }
      }
    }
    .footer-phone {
      font-size: 24px;
      padding: 10px 0 0;
      font-weight: 600;
      color: $app-primary-color;
    }
    .footer-email {
      padding-top: 10px;
      color: $app-primary-color;
      text-decoration: underline;
    }
  }
  .footer-copyright-text {
    text-align: right;
    opacity: 0.7;
    font-size: clamp(11px, 2vw, 15px);
  }
}
.footer[data-type="type2"] {
  .footer-logo svg path {
    stroke: $client-primary-color !important;
  }
  .footer-contact {
    .footer-phone,
    .footer-email {
      color: #0d771e;
    }
    li {
      background-color: #0d771e;
    }
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    .footer-content {
      grid-template-areas:
        "logoArea"
        "contactArea"
        "listArea" !important;
      .footer-logo,
      .footer-contact,
      .footer-list {
        margin: auto;
      }
      .footer-list {
        flex-direction: column;
      }
      .footer-contact {
        text-align: center;
        ul {
          justify-content: center;
        }
      }
    }
    .footer-copyright-text {
      text-align: center;
    }
  }
}
