.topbar {
  position: relative;
  overflow: hidden;
  .topbar-content {
    background: #007fff;
    width: 100%;
    height: 520px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    .bg {
      position: absolute;
      width: 100vw;
      z-index: -1;
      right: 0;
      top: 0;
    }
    .topbar-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      svg {
        height: 115px;
        width: 131px;
      }
      ul {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-top: 30px;
        li {
          display: none;
          color: white;
          font-size: 20px;
          font-weight: 400;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .message {
      width: 60%;
    }
    .title {
      margin-top: 3%;
      font-weight: 500;
      color: white;
      font-size: clamp(21px, 4vw, 48px);
      text-transform: capitalize;
    }
    .description {
      font-size: clamp(14px, 3vw, 24px);
      color: white;
      margin-top: 15px;
    }
    button {
      background: white;
      margin-top: 25px;
    }
    .checks {
      margin-top: 15px;
      display: flex;
      gap: 15px;
      li {
        color: white;
        font-size: 22px;
        span {
          margin-right: 10px;
        }
        svg {
          height: 18px;
          width: 18px;
        }
        svg path {
          fill: white;
        }
      }
    }
  }
  .topbar-wave-style {
    width: 100vw;
    transform: translateY(-80px);
  }
  .event-cal-wrapper {
    width: clamp(200px, 40%, 400px);
    height: 55vw;
    right: 0px;
    position: absolute;
    top: -70px;
    .event-cal {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }
  }
  .event-img-cont {
    position: absolute;
    right: 12%;
    top: 30px;
    z-index: 2;
    img {
      width: 400px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .topbar {
    .event-cal-wrapper {
      height: 59vw;
      top: -25px;
    }
    .topbar-content {
      height: 500px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .topbar {
    .cl-section {
      text-align: center;
    }
    .message {
      width: 100% !important;
      .description:first-child {
        display: none;
      }
    }
    .topbar-content {
      padding-top: 10px;
      button {
        width: 100%;
      }
    }

    .event-cal-wrapper {
      margin-top: 20px;
      position: relative;
      width: 100%;
      max-height: 450px;
      top: 0;
      right: 0;
      text-align: center;
      height: 90vw;
      .event-cal {
        width: 80%;
        margin: auto;
        height: auto;
      }
    }
    .topbar-wave-style {
      display: none;
    }
  }
}
