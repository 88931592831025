.feature-main {
  .title {
    font-size: clamp(20px, 3.5vw, 48px);
    font-weight: 700;
  }
}
.industry-container {
  .banner-main-wrapper {
    .banner-main-hero-image {
      // object-fit: cover;
      display: none;
    }
    .banner-main-hero-image-bg {
      display: block;
    }
    .banner-main-content {
      color: white;
      .banner-main-content-title {
        background-color: rgba(0, 0, 0, 0.7);
      }
      .banner-main-content-brief {
        background-color: rgba(0, 0, 0, 0.6);
      }
      .banner-main-content-title,
      .banner-main-content-brief {
        padding-left: 11px;
        padding-right: 11px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .industry-container .banner-main-wrapper .banner-main {
    height: 500px !important;
  }
}
