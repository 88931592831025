$button-position: 75px;
#carousel-component {
  padding-bottom: $button-position;
  .grid {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .carousel-img {
    border-radius: 6px;
    height: clamp(140px, 30vw, 330px);
    width: 100%;
    object-fit: cover;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgb(240, 236, 236);
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
  }
  #left,
  #right {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    outline: none;
    z-index: 4;
    cursor: pointer;
    position: absolute;
    bottom: #{$button-position * -1};
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(36, 39, 44, 0.1);
    &:before {
      content: "";
      height: 10px;
      width: 10px;
      background: transparent;
      border-top: 3px solid #24272c;
      border-right: 3px solid #24272c;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  #left {
    right: calc(50% + 15px);
  }

  #right {
    left: calc(50% + 15px);
  }

  #left::before {
    -webkit-transform: translate(-25%, -50%) rotate(-135deg);
    -ms-transform: translate(-25%, -50%) rotate(-135deg);
    transform: translate(-25%, -50%) rotate(-135deg);
  }

  #right::before {
    -webkit-transform: translate(-75%, -50%) rotate(45deg);
    -ms-transform: translate(-75%, -50%) rotate(45deg);
    transform: translate(-75%, -50%) rotate(45deg);
  }
  .image-container {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to top,
        rgba(33, 37, 41, 1),
        rgba(33, 37, 41, 0.8) 20%,
        rgba(33, 37, 41, 0.1) 35%,
        rgba(33, 37, 41, 0)
      );
      border-radius: 6px;
    }
    .layover-content {
      color: white;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 11;
      padding: 0px 10% 11% 10%;
      display: none;
      font-size: clamp(13px, 2vw, 20px);
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0em;
      display: block;
    }
  }

  .image-container:hover {
    &::after {
      display: block;
    }
  }
  .heading {
    color: black;
    text-align: left;
    margin-bottom: 40px;
  }
  .carousel-header {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(18px, 3vw, 42px);
    line-height: var(--section-header-line-height);
    letter-spacing: 0px;
  }
  .carousel-subheader {
    font-size: clamp(16px, 1.5vw, 22px);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.006em;
  }
  .bqyzQp > div {
    margin: 0px;
  }
}
