@import "../../responsive/responsive.scss";
@import "../Utilities.scss";
// .home-container {
//   display: grid;
//   grid-auto-flow: row;
//   padding-bottom: 20px;
//   .pickup-component,
//   // .faq-component,
//   .carousel-component,
//   .downloadapp-component,
//   #pricing-component,
//   .footer-container {
//     max-width: $app-max-width;
//     padding-right: 20px;
//     padding-left: 20px;
//     margin: auto;
//     width: 100vw;
//   }
// }
.home-container.modal-open {
  overflow: hidden;
  // position: sticky;
  .BottomBar {
    display: none;
  }
}
#home-container {
  .home-first-section {
    display: flex;
    flex-direction: row;
  }
}
