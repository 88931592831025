@import "../../../Styles//Utilities.scss";
#pricing-component {
  .amount {
    margin-top: 5px;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #007fff;
  }
  .p-body {
    .p-row {
      height: auto;
      min-height: 40px;
      border-bottom: 1px solid #e9eef2;
    }
  }
  .trail-info {
    opacity: 0.8;
    height: 24px;
    margin-bottom: 10px;
  }
  .sectionTitle,
  .plan-info-sm {
    margin-top: 18px;
    display: none;
    .p-row {
      margin-bottom: 20px;
    }
    .trail-info {
      text-align: center;
    }
    button {
      // margin-top: 20px;
      color: white;
      background-color: $app-primary-color;
      width: 100% !important;
    }
  }

  .sectionTitle,
  .plan-info-sm .p-col:first-child {
    font-weight: 700;
    font-size: 20px;
  }
  .sectionTitle.p-row {
    border: none;
  }

  .p-row {
    display: flex;
    flex-direction: row;
    .p-col:first-child {
      width: 350px;
      padding-left: 8px;
    }
    .p-col:not(:first-child) {
      flex: 1;
      text-align: center;
    }
  }
  .p-section {
    .p-row:last-child {
      border: none;
    }
  }
  .p-section {
    .p-col {
      display: flex;
      align-items: center;
      .p-col:first-child {
      }
      &:not(:first-child) {
        justify-content: center;
      }
    }
  }
  .plan-name {
    font-size: 16px;
    opacity: 0.8;
  }
  .amount-period,
  .frequency {
    font-size: 14px;
    opacity: 0.7;
    margin-bottom: 10px;
  }
  .coming-soon {
    padding-left: 10px;
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    p {
      margin-bottom: 5px;
    }
  }
  .p-col-sub {
    padding-left: 30px !important;
  }

  .pricing-tabs {
    border: 1px solid #e4e4e4;
    // border-bottom: 2px solid #007fff;
    text-align: center;
    display: none;
    height: 41px; // border-radius: 5px 5px 0px 0px;
    .pricing-tab {
      font-size: 14px;
      padding: 0px 15px;
      display: inline-block;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pricing-tab.active {
      color: #fff;
      background: #007fff;
    }
  }
  .pricing-arrow {
    margin-left: 10px;
    width: 15px;
    height: 14px;
  }
  .accordian .accordian-content {
    max-height: 0px;
    overflow: hidden;
    .p-row .p-col:first-child {
      padding-left: 30px;
    }
  }
  .accordian.active .accordian-content {
    max-height: 1000px;
    transition: all 0.4s ease-in-out;
  }
  // .accordian.active .sectionTitle {
  //   &::after {
  //     transform: rotate(-135deg);
  //   }
  // }
  .accordian .sectionTitle {
    span {
      margin-right: 10px;
      opacity: 0.7;
      font-weight: 400;
      width: 10px;
      display: inline-block;
    }
    position: relative;
    // &::after {
    //   content: "";
    //   border: solid rgba(36, 39, 44, 0.7);
    //   border-width: 0 3px 3px 0;
    //   display: inline-block;
    //   padding: 4px;
    //   position: absolute;
    //   right: 10px;
    //   top: calc(50% - 7px);
    //   height: clamp(1px, 1vw, 7px);
    //   width: clamp(1px, 1vw, 7px);
    //   transform: rotate(45deg);
    //   transition-duration: 0.4s;
    //   transition-property: transform;
    // }
  }
  .icon-checkmark {
    color: $app-primary-color;
  }
  .expand-collapse {
    color: $app-primary-color;
    font-size: 14px;
    margin-top: 20px;
    span {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  #pricing-component {
    .p-col.not-active {
      display: none;
    }
    .p-row.bottom-cta {
      margin-top: 10px;
      button {
        width: 100% !important;
      }
      .p-col:first-child {
        display: none;
      }
    }
    .p-row.plan-info {
      display: none;
    }
    .p-row .p-col:first-child {
      width: 270px !important;
    }
    .plan-info-sm {
      display: block;
      .p-row .p-col:first-child {
        width: 200px !important ;
      }
    }

    .pricing-tabs {
      display: flex !important;
    }
    .coming-soon {
      font-weight: 400 !important;
      font-size: 16px !important;
    }
  }
}
