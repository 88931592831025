.terms-of-service-container {
  max-width: 1200px;
  margin: auto;
  color: #777;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  .title {
    margin-bottom: 10px;
    font-size: 42px;
    line-height: 54px;
    font-weight: 300;
    text-align: center;
    color: black;
  }
  p {
    line-height: 24px;
    margin-bottom: 10px;
  }
  ul {
    padding-left: 40px;
    list-style: disc;
  }
}
