.SignupCatalog {
  color: white;
  padding: 70px 15%;
  text-align: center;
  background-image: url(../Topbar/topbanner_with_logo.svg);
  background-repeat: no-repeat;
  background-size: cover;
  .title {
    font-size: 42px;
  }
  .sub-title {
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 30px;
  }
  button {
    background-color: white;
    font-size: 22px;
  }
}
@media only screen and (max-width: 992px) {
  .SignupCatalog {
    padding-left: 10%;
    padding-right: 10%;
    // background-size: auto;
    .title {
      font-size: 38px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .SignupCatalog {
    padding-left: 20px;
    padding-right: 20px;
    br {
      display: none;
    }
  }
}
@media only screen and (max-width: 480px) {
  .SignupCatalog {
    .title {
      font-size: 28px;
      line-height: 32px;
    }
  }
}
