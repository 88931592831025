@import "../../Styles/Utilities.scss";
#client-container {
  * {
    // font-family: "Rubik", sans-serif;
  }
  i.fa {
    font-family: "FontAwesome";
  }
  img {
    object-fit: cover;
  }
  .cl-section.top {
    // margin-top: 50px;
    padding-top: 40px;
    gap: 10vw;
    .wrapper {
      padding-bottom: 40px;
    }
    h1 {
      font-size: clamp(24px, 5vw);
      margin-bottom: 10px;
    }
    .desc {
      margin-bottom: 30px;
      font-size: 18px;
    }
  }
  .cl-section {
    padding-left: 20px;
    padding-right: 20px;
    label {
      font-weight: 500;
      font-size: clamp(20px, 4vw, 42px);
      margin-bottom: clamp(10px, 3%, 25px);
      display: inline-block;
    }
  }
  .cl-section.section-block {
    padding-top: clamp(25px, 5%, 60px);
    padding-bottom: clamp(25px, 5%, 60px);
  }
  .car-item {
    img {
      // border-radius: 4px;
    }
  }
  .service-input {
    border: 1px solid rgba(36, 39, 44, 0.15);
    box-sizing: border-box;
    border-radius: 0px;
    padding: 20px 20px;
    width: 100%;

    // margin-bottom: 30px;
    height: 56px;
    &::placeholder {
      font-weight: 500;
    }
  }
  .join-partner .title {
    font-weight: 700;
    font-size: clamp(22px, 2.4vw, 32px);
    margin-bottom: 20px;
  }
  .join-partner .subtitle {
    font-size: 16px;
    line-height: 30px;
    opacity: 0.8;
    margin-bottom: 15px;
  }
  .join-partner .join-partner-content {
    display: flex;
    flex-direction: row;
    background-color: rgba(13, 119, 30, 0.1);
    // padding: 75px 60px;
    border-radius: 12px;
    padding: clamp(30px, 4.5vw, 70px) clamp(30px, 3vw, 70px);
    gap: 5%;
  }
  .join-partner .right-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-left: 5%;
  }
  .join-partner {
    .join-partner-content {
      display: flex;
      flex-direction: row;
      background-color: rgba(13, 119, 30, 0.1);
      // padding: 75px 60px;
      border-radius: 12px;
      padding: clamp(30px, 4.5vw, 70px) clamp(30px, 3vw, 70px);
    }

    img {
      object-fit: contain;
      width: 40%;
      height: 220px;
    }
  }

  .testimonial {
    padding: 40px 30px;
    .profile {
      display: flex;
      flex-direction: row;
      img {
        border-radius: 100px;
        margin-right: 20px;
      }
      .user {
        font-weight: 700;
        font-size: clamp(20px, 2vw, 26px);
      }
      .location {
        font-size: 18px;
        font-weight: 400;
      }
      margin-bottom: 30px;
    }
    .comment-title {
      font-weight: 700;
      opacity: 0.7;
      font-size: 24px;
      margin-bottom: 16px;
    }
    .comment {
      font-size: 16px;
      opacity: 0.7;
      line-height: 28px;
    }
    .rating {
      margin-top: 20px;
      i {
        font-family: FontAwesome !important;
        color: $client-primary-color;
        width: unset;
        padding: 0px;
        margin-right: 15px;
      }
      // display: flex;
      // flex-direction: row;
    }
  }
  .hire-people {
    .hire-people-item-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .hire-people-item-content {
        width: calc((100% - 120px) / 5);
        img{
          object-fit: contain;
        }
      }
      .hire-people-item-arrow {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.arrow-right {
  position: absolute;
  right: 0px;
  // top: calc(50% - 25px);
  background-color: #24272c;
  border-radius: 100px;
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-family: FontAwesome !important;
    color: white;
  }
}
.arrow-left {
  position: absolute;
  left: 0px;
  top: calc(50% - 25px);
  background-color: #24272c;
  border-radius: 100px;
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  img {
    transform: rotate(180deg);
  }
}
#client-container {
  .featured-home-carousel-container > div > div > div > div > div {
    min-width: 0;
    margin: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
  // .header {
  //   background-color: white;
  //   .reach-title {
  //     color: $client-primary-color;
  //   }
  //   .h-left {
  //     color: $client-primary-color;
  //     svg {
  //       path {
  //         stroke: $client-primary-color;
  //       }
  //     }
  //   }
  //   .h-main-elements {
  //     li {
  //       color: $client-primary-color;
  //     }
  //     li:last-child {
  //       color: white;
  //       background-color: $client-primary-color;
  //     }
  //   }
  // }
  .footer {
    .footer-logo svg path {
      stroke: $client-primary-color !important;
    }
    .footer-contact {
      .footer-phone,
      .footer-email {
        color: #24272c;
      }
      li {
        background-color: #24272c;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  #client-container {
    .cl-section.top {
      background-color: #e7f1e9;
      img {
        display: none;
      }
      button {
        // width: 100% !important;
      }
    }

    .join-partner {
      padding-left: 0px !important;
      padding-right: 0px !important;
      .join-partner-content {
        flex-direction: column !important;
        img {
          width: 100% !important;
        }
        .right-cont {
          margin: 0;
        }
      }
    }
    .app-connection {
      padding-left: 0px !important;
      padding-right: 0px !important;
      .app-connection-content {
        flex-direction: column !important;
        padding-left: clamp(30px, 3vw, 70px) !important;
        .partner-img {
          width: 70% !important;
          margin: auto;
          margin-bottom: 10px;
        }
      }
      .sms-link-input {
        flex: 1;
        width: auto;
      }
      button {
        // flex-basis: 100%;
        // width: 100% !important;
        height: 45px;
      }
    }
    .arrow-right {
      top: calc(50% - 25px) !important;
    }
    .hire-people {
      .hire-people-item-arrow {
        display: none !important;
      }
      .hire-people-item-container {
        flex-direction: column !important;
      }
      .hire-people-item-content {
        width: 100% !important;
        padding-bottom: 10px !important;
        img{
          object-fit: cover !important;
        }
      }
    }
  }
}
